<template>
    <el-main>
        <el-tabs v-model="activeName">
            <el-tab-pane v-for="item in tabList" :key="item.step" :label="item.name" :name="item.step"></el-tab-pane>
        </el-tabs>
        <!-- 展示需要收集的数据 -->
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <div v-if="activeName == 'first'">
                <el-form-item label="提现设置"></el-form-item>
                <el-form-item label="最低提现金额" prop="">
                    <el-input v-model="ruleForm.minimum_withdrawal_amount"></el-input>
                </el-form-item>
                <el-form-item label="结算说明"></el-form-item>
                <el-form-item label="结算说明" prop="">
                    <RichText :richTxt="ruleForm.withdrawal_explain" @soninfo="val => (ruleForm.withdrawal_explain = val)">
                    </RichText>
                </el-form-item>
            </div>
            <div v-if="activeName == 'second'">
                <el-form-item label="微信小程序AppID" prop="">
                    <el-input v-model="ruleForm.store_appid_set.app_id"></el-input>
                </el-form-item>
                <el-form-item label="秘钥" prop="">
                    <el-input v-model="ruleForm.store_appid_set.secret"></el-input>
                </el-form-item>
            </div>
            <div v-if="activeName == 'third'">
                <el-form-item label="微信小程序AppID" prop="">
                    <el-input v-model="ruleForm.wifi_appid_set.app_id"></el-input>
                </el-form-item>
                <el-form-item label="秘钥" prop="">
                    <el-input v-model="ruleForm.wifi_appid_set.secret"></el-input>
                </el-form-item>
                <el-form-item label="跳转路径" prop="">
                    <el-input v-model="ruleForm.wifi_appid_set.path"></el-input>
                </el-form-item>
                <el-form-item label="原始ID" prop="">
                    <el-input v-model="ruleForm.wifi_appid_set.original_id"></el-input>
                </el-form-item>
            </div>
            <div v-if="activeName == 'fourth'">
                <el-form-item label="微信小程序AppID" prop="">
                    <el-input v-model="ruleForm.scan_code_set.app_id"></el-input>
                </el-form-item>
                <el-form-item label="秘钥" prop="">
                    <el-input v-model="ruleForm.scan_code_set.secret"></el-input>
                </el-form-item>
                <el-form-item label="商户号Mch_ID" prop="">
                    <el-input v-model="ruleForm.scan_code_set.m_id"></el-input>
                </el-form-item>
                <el-form-item label="支付秘钥APIkey" prop="">
                    <el-input v-model="ruleForm.scan_code_set.api_key"></el-input>
                </el-form-item>
                <el-form-item label="跳转路径" prop="">
                    <el-input v-model="ruleForm.scan_code_set.path"></el-input>
                </el-form-item>
                <el-form-item label="原始ID" prop="">
                    <el-input v-model="ruleForm.scan_code_set.original_id"></el-input>
                </el-form-item>
                <el-form-item label="apiclient_cert">
                    <div class="upBox">
                        <el-input placeholder="请上传apiclient_key证书" disabled v-model="ruleForm.scan_code_set.apiclient_cert">
                        </el-input>
                        <div class="uploadFile">
                            <el-button type="primary">上传文件</el-button>
                            <input type="file" ref="inputer0" accept=".pem" @change="getFile(0)" />
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="apiclient_key">
                    <div class="upBox">
                        <el-input placeholder="请上传apiclient_key证书" disabled v-model="ruleForm.scan_code_set.apiclient_key">
                        </el-input>
                        <div class="uploadFile">
                            <el-button type="primary">上传文件</el-button>
                            <input type="file" ref="inputer1" accept=".pem" @change="getFile(1)" />
                        </div>
                    </div>
                </el-form-item>
            </div>
            <div v-if="activeName == 'fifth'">
                <el-form-item label="WIFI连接设置"></el-form-item>
                <el-form-item label="连接有效天数:" prop="">
                    <el-input v-model="ruleForm.link_valid_day">
                        <template slot="append">天</template>
                    </el-input>
                    <span class="tips">用户在N天内多次连接wifi仅算1次有效</span>
                </el-form-item>
                <el-form-item label="单次连接佣金:" prop="">
                    <el-input v-model="ruleForm.single_link_price">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">用户有效连接1次，给出的总佣金数</span>
                </el-form-item>
                <el-form-item label="其他奖励设置"></el-form-item>
                <el-form-item label="扫码点餐佣金:" prop="">
                    <el-input v-model="ruleForm.scan_code_price">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">用户产生1笔有效订单，所支付总佣金</span>
                </el-form-item>
                <el-form-item label="扫码点餐佣金起算金额:" prop="">
                    <el-input v-model="ruleForm.single_scan_price">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span>
                </el-form-item>
                <el-form-item label="用户红包收益:" prop="">
                    <el-input style="width:190px" v-model="ruleForm.red_mix_price">
                        <template slot="append">元</template>
                    </el-input>
                    ~
                    <el-input style="width:190px" v-model="ruleForm.red_max_price">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">用户进入可以领红包金额区间，产生随机金额</span>
                </el-form-item>
                <el-form-item label="消费奖励政策:">运营商、发展商、商家都有奖励</el-form-item>
                <el-form-item label="扫码点餐是否有激励:" prop="">
                    <el-radio-group v-model="ruleForm.is_limit_scan_code">
                        <el-radio :label="1">无激励</el-radio>
                        <el-radio :label="0">有激励</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="每笔有效消费奖励:" prop="">
                    <el-input v-model="ruleForm.vail_pay_incentive">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">用户产生1笔有效订单,所奖励的总佣金</span>
                </el-form-item>
                <el-form-item label="奖励有效期限:" prop="">
                    <el-date-picker style="width:190px" v-model="ruleForm.vail_pay_start_time" type="date"
                        placeholder="选择开始日期">
                    </el-date-picker>
                    ~
                    <el-date-picker style="width:190px" v-model="ruleForm.vail_pay_end_time" type="date"
                        placeholder="选择结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="连接奖励政策:">运营商专享奖励</el-form-item>
                <el-form-item label="有效连接次数达到:" prop="">
                    <el-input style="width:190px" v-model="ruleForm.vail_connect_times">
                        <template slot="append">次/码</template>
                    </el-input>
                    奖励运营商
                    <el-input style="width:190px" v-model="ruleForm.vail_connect_price">
                        <template slot="append">元/码</template>
                    </el-input>
                    <span class="tips">用户产生1笔有效订单算1次,连接wifi成功算一次</span>
                </el-form-item>
                <el-form-item label="奖励有效期限:" prop="">
                    <el-date-picker style="width:190px" v-model="ruleForm.vail_connect_start_time" type="date"
                        placeholder="选择开始日期">
                    </el-date-picker>
                    ~
                    <el-date-picker style="width:190px" v-model="ruleForm.vail_connect_end_time" type="date"
                        placeholder="选择结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="限制连续wifi时长:" prop="">
                    <el-input v-model="ruleForm.limit_user_link_wifi_time">
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="企业提现政策:" prop="">
                    <div>企业提现扣除费率</div>
                </el-form-item>
                <el-form-item label="企业提现扣除费率基准金额:" prop="">
                    <el-input v-model="ruleForm.base_amount">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="企业提现扣除费率:" prop="">
                    <el-input v-model="ruleForm.base_amount_rate">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="贴码视频上传规则:" prop="">
                </el-form-item>
                <el-form-item label="贴码视频上传限时:" prop="">
                    <el-input v-model="ruleForm.upload_limit_time">
                        <template slot="append">天</template>
                    </el-input>
                    <span class="tips">超过此时限，企业未上传贴码视频，店铺会被拉黑</span>
                </el-form-item>
            </div>
            <div v-if="activeName == 'sixth'">
                <el-form-item label="结算细则:" prop="">
                    <ReadyUploadSource @getSource="getSource" @changeOrder="list => (ruleForm.settled_detail = list)"
                        @removeThis="index => ruleForm.settled_detail.splice(index, 1)" :isMany="true"
                        :manyPath="ruleForm.settled_detail" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
                </el-form-item>
            </div>
            <div v-if="activeName == 'seventh'">
                <el-form-item label="开店激励" style="font-weight: bold;"></el-form-item>
                <el-form-item label="小程序开店奖励:" prop="">
                    <el-input v-model="ruleForm.online_profit">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">小程序上线成功，奖励给出</span>
                </el-form-item>
                <el-form-item label="点餐配送" style="font-weight: bold;"></el-form-item>
                <el-form-item label="配送订单流水对商家抽佣:" prop="">
                    <el-input v-model="ruleForm.merchant_fee">
                    </el-input>
                </el-form-item>
                <el-form-item label="配送订单佣金:" prop="">
                    <el-input v-model="ruleForm.delivery_commission">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="配送费用佣金:" prop="">
                    <el-input v-model="ruleForm.delivery_fee">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
            </div>
            <div v-if="activeName == 'eighth'">
                <el-form-item label="开店激励" style="font-weight: bold;"></el-form-item>
                <el-form-item label="贴码审核通过激励:" prop="">
                    <el-input v-model="ruleForm.opening_incentives">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">只奖励给运营商</span>
                </el-form-item>
                <el-form-item label="开启奖励2.0:" prop="">
                    <el-switch v-model="ruleForm.new_link_profit" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
                <!--<el-form-item label="有效连接条件达成激励:" prop="">
                    <el-input v-model="ruleForm.new_link_profit">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">只奖励给运营商</span>
                </el-form-item>
                <el-form-item label="有效连接达成次数:" prop="">
                    <el-input v-model="ruleForm.new_link_time">
                        <template slot="append">次</template>
                    </el-input>
                    <span class="tips">新人连接wifi，第三方活动有效订单，聚合配送有效订单算作有效连接次数。到店点餐、到店支付、商家自配送不算有效连接</span>
                </el-form-item>-->
                <template v-if="ruleForm.new_link_profit">
                    <el-form-item label="第三方生活服务" style="font-weight: bold;"></el-form-item>
                    <el-form-item label="第三方服务有效订单激励:" prop="">
                        <div class="eighthitem" v-for="(item, index) in ruleForm.third_party_profit" :key="index">
                            <div class="eighthbox">
                                <div class="eighthrow">
                                    <div class="eighthrow_label">订单费用最小值</div>
                                    <el-input v-model="item.min_amount" type="number">
                                        <template slot="append">元</template>
                                    </el-input>
                                    <div class="eighthrow_label">订单费用最大值</div>
                                    <el-input v-model="item.max_amount" type="number">
                                        <template slot="append">元</template>
                                    </el-input>
                                </div>
                                <div class="eighthrow" v-for="(d, d_i) in item.data" :key="d_i">
                                    <el-input v-model="d.prize">
                                        <template slot="append">元</template>
                                    </el-input>
                                    <div class="eighthrow_label">奖励几率:</div>
                                    <el-input v-model="d.odds">
                                        <template slot="append">%</template>
                                    </el-input>
                                    <div class="eighthrow_label tips" v-if="d_i == 0">如果添加几率不满100%，其余的概率都奖励1元</div>
                                    <div class="eighthrow_label" v-else>
                                        <el-button type="text" @click="item.data.splice(d_i, 1)">删除</el-button>
                                    </div>
                                </div>
                                <el-button type="text" @click="item.data.push({
                                    prize: '',
                                    odds: ''
                                })">+添加奖励梯度</el-button>
                            </div>
                            <el-button type="text" v-if="index > 0"
                                @click="ruleForm.third_party_profit.splice(index, 1)">删除</el-button>
                        </div>
                        <el-button type="text" @click="addThirdPartyProfit">+添加订单范围</el-button>
                    </el-form-item>
                </template>
            </div>
            <div v-if="activeName == 'ninth'">
                <el-form-item label="开启奖励:" prop="">
                    <el-switch v-model="ruleForm.vantone_code.is_rewards" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
                <el-form-item label="物流激励" style="font-weight: bold;"></el-form-item>
                <el-form-item label="物流发件奖励:" prop="">
                    <el-input v-model="ruleForm.vantone_code.send">
                        <template slot="append">%</template>
                    </el-input>
                    <span class="tips">用户发送快递支付金额的比例，判断用户进入使用哪个商家码或小程序分享</span>
                </el-form-item>
                <el-form-item label="物流收件奖励:" prop="">
                    <el-input v-model="ruleForm.vantone_code.get">
                        <template slot="append">%</template>
                    </el-input>
                    <span class="tips">判断收件人区域，奖励给此区域的运营商，如果有多个，每个人都奖励</span>
                </el-form-item>
            </div>
            <div v-if="activeName == 'tenth'" class="tenthitem">
                <el-form-item label="开启奖励:" prop="">
                    <el-switch v-model="ruleForm.charge.is_open" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
                <el-form-item label="充电金额可分佣金比例:" prop="">
                    <el-input v-model="ruleForm.charge.rate">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="充电金额设置" style="font-weight: bold;"></el-form-item>
                <el-form-item label="时间+金额:" prop="">
                    <div class="inputrow" v-for="(item, index) in ruleForm.charge.price_set" :key="index">
                        <el-input v-model="item.time">
                            <template slot="append">小时</template>
                        </el-input>
                        <el-input v-model="item.price">
                            <template slot="append">元</template>
                        </el-input>
                        <el-button type="text" @click="ruleForm.charge.price_set.splice(index, 1)" v-if="index > 0"
                            style="margin-left:10px">删除</el-button>
                    </div>
                    <el-button type="text" @click="ruleForm.charge.price_set.push({ time: '', price: '' })">+
                        添加时间金额</el-button>
                </el-form-item>
            </div>
        </el-form>
        <Preservation @preservation="Preservation"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation'
import RichText from '@/components/richText';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash'
export default {
    components: {
        Preservation,
        RichText,
        ReadyUploadSource
    },
    data () {
        return {
            activeName: 'fifth',
            tabList: [
                { name: '基础设置', step: 'first' },
                { name: '门店助手小程序', step: 'second' },
                { name: 'wifi小程序', step: 'third' },
                { name: '扫码点餐小程序', step: 'fourth' },
                { name: '奖励设置', step: 'fifth' },
                { name: '结算细则', step: 'sixth' },
                { name: '微云仓奖励', step: 'seventh' },
                { name: '微云仓2.0奖励', step: 'eighth' },
                { name: '万通码', step: 'ninth' },
                { name: '街快充', step: 'tenth' },
            ],
            ruleForm: {
                minimum_withdrawal_amount: '',
                withdrawal_explain: '',
                store_appid_set: {
                    app_id: '',
                    secret: ''
                },
                wifi_appid_set: {
                    app_id: '',
                    secret: '',
                    path: '',
                    original_id: ''
                },
                scan_code_set: {
                    app_id: '',
                    m_id: '',
                    secret: '',
                    apiclient_cert: '',
                    apiclient_key: '',
                    path: '',
                    original_id: ''
                },
                link_valid_day: '',
                single_link_price: '',
                scan_code_price: '',
                single_scan_price: '',
                red_mix_price: '',
                red_max_price: '',
                vail_pay_incentive: '',
                vail_pay_start_time: null,
                vail_pay_end_time: null,
                vail_connect_times: '',
                vail_connect_price: '',
                vail_connect_start_time: null,
                vail_connect_end_time: null,
                limit_user_link_wifi_time: '',
                is_limit_scan_code: 1,
                base_amount: '',
                base_amount_rate: '',
                upload_limit_time: '',
                settled_detail: '',
                online_profit: '',
                merchant_fee: '',
                delivery_commission: '',
                delivery_fee: '',

                new_link_profit: 0,
                opening_incentives: '',

                third_party_profit: [],
                //new_link_profit: '',
                //new_link_time: ''
                vantone_code: {
                    is_rewards: 1,//1--开启 0--关闭
                    send: '',
                    get: ''
                },
                charge: {
                    is_open: 1,
                    rate: '',
                    price_set: [
                        {
                            time: '',
                            price: ''
                        }
                    ]
                }
            },
            rules: {}
        }
    },
    created () {
        this.getSet()
    },
    methods: {
        // 获取设置
        getSet () {
            this.$axios.post(this.$api.set.TwitterSetInfo).then(res => {
                if (res.code === 0) {
                    this.ruleForm.minimum_withdrawal_amount = res.result.minimum_withdrawal_amount
                    this.ruleForm.withdrawal_explain = res.result.withdrawal_explain
                    if (res.result.store_appid_sets) {
                        this.ruleForm.store_appid_set = res.result.store_appid_sets
                    }
                    if (res.result.wifi_appid_sets) {
                        this.ruleForm.wifi_appid_set = res.result.wifi_appid_sets
                    }
                    if (res.result.scan_code_sets) {
                        this.ruleForm.scan_code_set = res.result.scan_code_sets
                    }
                    this.ruleForm.link_valid_day = res.result.link_valid_day
                    this.ruleForm.single_link_price = res.result.single_link_price
                    this.ruleForm.scan_code_price = res.result.scan_code_price
                    this.ruleForm.single_scan_price = res.result.single_scan_price
                    this.ruleForm.red_mix_price = res.result.red_mix_price
                    this.ruleForm.red_max_price = res.result.red_max_price
                    this.ruleForm.limit_user_link_wifi_time = res.result.limit_user_link_wifi_time
                    //
                    this.ruleForm.vail_pay_incentive = res.result.vail_pay_incentive
                    this.ruleForm.vail_pay_start_time = res.result.vail_pay_start_time ? res.result.vail_pay_start_time * 1000 : null
                    this.ruleForm.vail_pay_end_time = res.result.vail_pay_end_time ? res.result.vail_pay_end_time * 1000 : null
                    this.ruleForm.vail_connect_times = res.result.vail_connect_times
                    this.ruleForm.vail_connect_price = res.result.vail_connect_price
                    this.ruleForm.vail_connect_start_time = res.result.vail_connect_start_time ? res.result.vail_connect_start_time * 1000 : null
                    this.ruleForm.vail_connect_end_time = res.result.vail_connect_end_time ? res.result.vail_connect_end_time * 1000 : null
                    this.ruleForm.is_limit_scan_code = res.result.is_limit_scan_code
                    this.ruleForm.base_amount = res.result.base_amount
                    this.ruleForm.base_amount_rate = res.result.base_amount_rate
                    this.ruleForm.upload_limit_time = res.result.upload_limit_time
                    this.ruleForm.settled_detail = res.result.settled_detail ? (JSON.parse(res.result.settled_detail) ? JSON.parse(res.result.settled_detail) : []) : []

                    this.ruleForm.online_profit = res.result.online_profit
                    this.ruleForm.merchant_fee = res.result.merchant_fee
                    this.ruleForm.delivery_commission = res.result.delivery_commission
                    this.ruleForm.delivery_fee = res.result.delivery_fee

                    this.ruleForm.opening_incentives = res.result.opening_incentives
                    this.ruleForm.new_link_profit = res.result.new_link_profit

                    this.ruleForm.third_party_profit = res.result.third_party_profit ? (JSON.parse(res.result.third_party_profit) || []) : []
                    //this.ruleForm.new_link_time = res.result.new_link_time

                    if (res.result.vantone_code && res.result.vantone_codes) {
                        this.ruleForm.vantone_code = res.result.vantone_codes
                        this.ruleForm.vantone_code.is_rewards = this.ruleForm.vantone_code.is_rewards * 1
                    }

                    if (res.result.charges.price_set) {
                        this.ruleForm.charge = res.result.charges
                        this.ruleForm.charge.is_open = this.ruleForm.charge.is_open * 1
                    }
                    //console.log(this.ruleForm)
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        // 上传文件
        getFile (status) {
            let files = null
            let that = this
            if (status == 0) {
                files = this.$refs.inputer0.files[0]
            } else if (status == 1) {
                files = this.$refs.inputer1.files[0]
            }
            let formData = new FormData()
            formData.append('cert', files)
            this.$axios.uploadFile(this.$api.upload.addCert, formData).then((res) => {
                if (res.code == 0) {
                    that.$message.success('上传成功')
                    if (status == 0) {
                        this.ruleForm.scan_code_set.apiclient_cert = res.result.path
                        this.$refs.inputer0.files[0].value = ''
                    } else {
                        this.ruleForm.scan_code_set.apiclient_key = res.result.path
                        this.$refs.inputer1.files[0].value = ''
                    }
                } else {
                    that.$message.error(`${res.msg}`)
                }
            })
        },
        // 点击保存
        Preservation () {
            //if (!this.checkData()) {
            //    this.$message.warning('请检查各个选项的数据是否填写完整')
            //    return
            //}
            let obj = _.cloneDeep(this.ruleForm)
            obj.vail_pay_start_time = Math.floor(obj.vail_pay_start_time / 1000)
            obj.vail_pay_end_time = Math.floor(obj.vail_pay_end_time / 1000)
            obj.vail_connect_start_time = Math.floor(obj.vail_connect_start_time / 1000)
            obj.vail_connect_end_time = Math.floor(obj.vail_connect_end_time / 1000)
            this.$axios.post(this.$api.set.TwitterSet, obj).then(res => {
                if (res.code == 0) {
                    this.$message.success('设置成功')
                    this.getSet()
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        // 保存前校验数据
        checkData () {
            let flag = true
            for (const key in this.ruleForm) {
                if (this.ruleForm[key] === '') {
                    console.log(key, '没填')
                    flag = false
                    break
                }
                if (typeof this.ruleForm[key] === 'object') {
                    let sonFlag = true
                    for (const son in this.ruleForm[key]) {
                        if (this.ruleForm[key][son] === '') {
                            console.log(key, son, '没填')
                            sonFlag = false
                            break
                        }
                    }
                    flag = sonFlag
                    if (!flag) break
                }
            }
            return flag
        },
        getSource (list) {
            list.map(item => {
                this.ruleForm.settled_detail.push(item.path);
            });
        },
        addThirdPartyProfit () {
            this.ruleForm.third_party_profit.push({
                min_amount: '',
                max_amount: '',
                data: [
                    {
                        prize: '',
                        odds: ''
                    }
                ]
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
    min-height: 100%;
    padding-bottom: 80px;

    .tips {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-left: 20px;
    }

    .el-input {
        width: 400px;
    }

    .uploadFile {
        position: relative;
        color: #1467ff;
        cursor: pointer;

        &>input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
        }
    }

    .upBox {
        display: flex;
        align-items: center;
    }

}

.eighthitem {
    display: flex;
    align-items: center;

    &+.eighthitem {
        margin-top: 15px;
    }

    .eighthbox {
        border: 1px solid #eee;
        padding: 15px 20px;
        width: 900px;
        margin-right: 10px;

        .eighthrow {
            display: flex;
            align-items: center;

            &+.eighthrow {
                margin-top: 10px;
            }

            .eighthrow_label {
                margin-right: 10px;
            }

            .el-input {
                width: 200px;

                &+.eighthrow_label {
                    margin-left: 20px;
                }
            }
        }
    }
}

.tenthitem {
    .inputrow {
        &+.inputrow {
            margin-top: 10px;
        }

        .el-input {
            width: 190px;

            &+.el-input {
                margin-left: 20px;
            }
        }
    }
}
</style>